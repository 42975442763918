export default [
    {
        type: "selection",
        width: 38,
        align: "center",
    },
    {
        title: 'Available Users',
        key: 'UserName',
        minWidth: 80,
    },

]
