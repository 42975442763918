<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle
      title="Role User Mapping"
      slot="title"
      link="/helpContent/RoleUserMapping"
    />
    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-4">
          <FormSelect
            label="Roles"
            :items="roleList"
            item-name="Name"
            item-value="id"
            v-model="form.selectedRole"
            :onChange="getUserByRoleId"
            rules="required"
          />
        </div>
      </FormRow>
      <FormRow
        style="padding: 0px 110px 0px 110px;margin-bottom: 0px !important;"
      >
        <div
          class="col-sm-6"
          style="display: flex;
    justify-content: space-between;"
        >
          <div>&nbsp;</div>
          <div :class="$style.headingBold">
            Available Users
          </div>
          <div @click="() => add()" style="cursor: pointer;float:right">
            Move
            <span :class="$style.moveRight">&#62;&#62;</span>
          </div>
        </div>
        <div
          class="col-sm-6"
          style="    display: flex;
    justify-content: space-between;"
        >
          <div @click="() => remove()" style="cursor: pointer;">
            <span :class="$style.moveLeft">&#60;&#60;</span>
            Move
          </div>
          <div :class="$style.headingBold">
            Assigned Users
          </div>
          <div>&nbsp;</div>
        </div>
      </FormRow>
      <FormRow style="padding: 0px 110px 0px 110px;">
        <div class="col-sm-6" :class="$style.dataTableCon">
          <DataTable
            :actions="true"
            :data="menuList"
            :columns="availableUsers"
            :is-loading="isLoading"
            @on-selection-change="selectChange"
          >
          </DataTable>
        </div>
        <div class="col-sm-6" :class="$style.dataTableCon">
          <DataTable
            :actions="true"
            :data="allocateList"
            :columns="assignedUsers"
            :is-loading="isLoading"
            @on-selection-change="selectChange"
          >
          </DataTable>
        </div>
      </FormRow>
    </ValidationObserver>
    <br />
    <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton
            type="info"
            @click="
              () => {
                $router.go(-1);
              }
            "
            >Back</FormButton
          >

          <FormButton type="success" @click="sendData()">Save</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>

    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        <FormButton
          @click="
            () => {
              $router.go(-1);
            }
          "
          >Ok</FormButton
        >
        <FormButton @click="() => (showMessage.isVisible = false)"
          >Stay On Same Page</FormButton
        >
      </div>
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import {
  getAllRoles,
  getAllUsersByRoleId,
  addUpdateUserByRole,
  getAllocatedUserByRole,
} from "../api";
import { ValidationObserver } from "vee-validate";
import availableUsers from "./availableUsers";
import assignedUsers from "./assignedUsers";
import DataTable from "Components/DataTable";
export default {
  name: "Mapping",
  mixins: [loadingMixin],
  components: {
    FormWrapper,
    PageTitle,
    ValidationObserver,
    DataTable,
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      form: {
        selectedRole: null,
      },
      roleList: [],
      menuList: [],
      items: [],
      availableUsers,
      assignedUsers,
      allocateList: [],
      selectedRows: [],
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: {},
  created() {
    this.getUserList();
  },
  methods: {
    selectChange(data) {
      this.selectedRows = data;
    },
    add() {
      if (this.selectedRows.length !== 0) {
        let removeIds = [];
        // this.allocateList=[];
        // In this loop get remove value and add them allocate list
        for (let i = 0; i < this.selectedRows.length; i++) {
          removeIds.push(this.selectedRows[i].Id);
          this.allocateList.push(this.selectedRows[i]);
        }
        // remove list in MenuList
        let tempArr = [];
        for (let j = 0; j < this.menuList.length; j++) {
          if (removeIds.indexOf(this.menuList[j].Id) === -1) {
            tempArr.push(this.menuList[j]);
          }
        }
        // first MenuList empty then assign tempArr so that data show
        this.menuList = [];
        this.menuList = tempArr;
        this.selectedRows = [];
      }
    },
    remove() {
      if (this.selectedRows.length !== 0) {
        let tempArr = [];
        let removeId = [];
        let tempMenuList = [];
        for (let j = 0; j < this.selectedRows.length; j++) {
          removeId.push(this.selectedRows[j].Id);
        }
        for (let i = 0; i < this.allocateList.length; i++) {
          if (removeId.indexOf(this.allocateList[i].Id) === -1) {
            tempArr.push(this.allocateList[i]);
          }
        }
        for (let l = 0; l < this.allocateList.length; l++) {
          if (removeId.indexOf(this.allocateList[l].Id) !== -1) {
            tempMenuList.push(this.allocateList[l]);
          }
        }
        let flatArr = [];
        flatArr = this.menuList;
        flatArr.push(tempMenuList);
        flatArr = flatArr.flat();
        this.menuList = flatArr;
        this.allocateList = [];
        this.allocateList = tempArr;
        this.selectedRows = [];
      }
    },
    sendData() {
      var result = [];
      if (this.allocateList) {
        this.allocateList.forEach((e) => {
          result.push(e.Id);
        });
        const data = {
          userId: result.toString(),
          roleId: this.form.selectedRole,
        };
        addUpdateUserByRole(data).then((res) => {
          this.showMessage.isVisible = true;
          this.showMessage.message = res.data[0].message;
        });
      }
    },
    getUserList() {
      getAllRoles().then((res) => {
        this.roleList = res.data;
      });
    },
    getUserByRoleId(id) {
      const data = {
        roleId: id,
      };
      getAllUsersByRoleId(data).then((res) => {
        this.menuList = res.data;
      });

      getAllocatedUserByRole(data).then((res) => {
        this.allocateList = res.data;
      });
    },
  },
};
</script>
<style lang="scss" module>
thead {
  display: none;
}
.moveRight {
  color: #03ac13;
  font-weight: bold;
}
.moveLeft {
  color: red;
  font-weight: bold;
}
.dataTableCon {
  overflow: auto;
  max-height: 400px;
  height: 100%;
}
.headingBold{
  font-weight: bold;
  font-size: 14px;
}
</style>
